import { OptionModel } from '@retail/backoffice-ui';
import { useMemo } from 'react';

const START_YEAR = 2023;

export function useYearOptions() {
  const endYear = new Date().getFullYear() + 6;

  return useMemo(
    () =>
      Array.from({ length: endYear - START_YEAR }).map(
        (_, index): OptionModel => {
          const value = index + START_YEAR;

          return {
            value,
            label: value
          };
        }
      ),
    [endYear]
  );
}
