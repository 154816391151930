import cn from './styles.less';
interface Props {
  title: string;
  ending: string;
}

export function OutOfNet({ title, ending }: Props) {
  return (
    <section className={cn.section}>
      <div>{title}</div>
      <div>{ending}</div>
    </section>
  );
}
