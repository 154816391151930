import { getMonth } from 'date-fns';
import { useCallback } from 'react';

import { useSearchSalesScoreboardResultsLazyQuery } from '~/apollo/gql-types';
import { CohortType } from '~/constants/CohortType';
import { PeriodType } from '~/constants/PeriodType';

import { SearchFormModel } from '../types';

/**
 * @description
 * In order to have no issues with {@link reset} from {@link react-hook-form}
 * we have to have default values for all fields.
 * {@link reset} - only support non undefined value.
 */
export const DEFAULT_VALUES: Required<SearchFormModel> = {
  countryCode: null,
  cohort: CohortType.VERIFIED,
  periodType: PeriodType.MONTHLY,
  period: getMonth(new Date()) + 1,
  year: new Date().getFullYear()
};

export function useDataSource() {
  const [submit, { data, loading }] = useSearchSalesScoreboardResultsLazyQuery({
    notifyOnNetworkStatusChange: true
  });

  const dataSource = data?.searchSalesScoreboardResults;

  const onSubmit = useCallback(
    ({ countryCode, ...params }: SearchFormModel) => {
      submit({
        variables: {
          search: {
            ...params,
            countryCodes: [countryCode]
          }
        }
      });
    },
    [submit]
  );

  return {
    dataSource,
    loading,
    onSubmit
  };
}
