import { SearchOutlined } from '@ant-design/icons';
import { OptionModel, SelectControlled } from '@retail/backoffice-ui';
import { Button, Card, Col, Form, Row } from 'antd';
import { getMonth, getWeek } from 'date-fns';
import {
  BaseSyntheticEvent,
  Dispatch,
  PropsWithChildren,
  useEffect
} from 'react';
import { Control, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PeriodType } from '~/constants/PeriodType';

import { useCohortOptions } from '../hooks/useCohortOptions';
import { usePeriodOptions } from '../hooks/usePeriodOptions';
import { usePeriodTypeOptions } from '../hooks/usePeriodTypeOptions';
import { useYearOptions } from '../hooks/useYearOptions';
import { SearchFormModel } from '../types';

import { FieldCol } from './FieldCol';
import cn from './style.less';

const GAP = 16;

interface Props {
  loading: boolean;
  disabled: boolean;
  countryCodeOptions: OptionModel[];
  control: Control<SearchFormModel>;
  onSubmit: Dispatch<BaseSyntheticEvent>;
}

export function SearchForm({
  loading,
  disabled,
  countryCodeOptions,
  control,
  onSubmit
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const cohortOptions = useCohortOptions();
  const periodTypeOptions = usePeriodTypeOptions();
  const yearOptions = useYearOptions();
  const { setValue } = useFormContext<SearchFormModel>();

  const periodType = useWatch({ control, name: 'periodType' });
  const periodOptions = usePeriodOptions(periodType);

  useEffect(() => {
    const period =
      periodType === PeriodType.MONTHLY
        ? getMonth(new Date()) + 1
        : getWeek(new Date());

    setValue('period', period);
  }, [periodType, setValue]);

  return (
    <Card title={t('bo.salesScoreboard.searchForm.title')}>
      <Form
        disabled={loading}
        onFinish={onSubmit}
        data-qa-selector="searchForm"
        className={cn.form}
      >
        <Row gutter={[GAP, GAP]}>
          <FieldCol>
            <SelectControlled
              required
              qaSelector="countryCode"
              label={t('bo.salesScoreboard.searchForm.field.countryCode')}
              options={countryCodeOptions}
              controllerProps={{
                name: 'countryCode',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <SelectControlled
              required
              qaSelector="cohort"
              label={t('bo.salesScoreboard.searchForm.field.cohort')}
              options={cohortOptions}
              controllerProps={{
                name: 'cohort',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <SelectControlled
              required
              qaSelector="periodType"
              label={t('bo.salesScoreboard.searchForm.field.periodType')}
              options={periodTypeOptions}
              controllerProps={{
                name: 'periodType',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <SelectControlled
              required
              qaSelector="period"
              label={t('bo.salesScoreboard.searchForm.field.period')}
              options={periodOptions}
              controllerProps={{
                name: 'period',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <SelectControlled
              required
              qaSelector="year"
              label={t('bo.salesScoreboard.searchForm.field.year')}
              options={yearOptions}
              controllerProps={{
                name: 'year',
                control
              }}
            />
          </FieldCol>
          <Col flex="auto">
            <div className={cn.buttons}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={disabled}
                data-qa-selector="submit"
                icon={<SearchOutlined />}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
