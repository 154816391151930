// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZxIIjBQLh6uo7459LQSS .antd-4-21-7-form-item-label {\n  padding-bottom: 0 !important;\n}\n.ZxIIjBQLh6uo7459LQSS .antd-4-21-7-form-item {\n  display: inline-block;\n  margin-bottom: 0;\n}\n.ZxIIjBQLh6uo7459LQSS .KK7euDkh_yDlwRDNCO4h {\n  display: flex;\n  width: 100%;\n  height: 100%;\n  align-items: end;\n  justify-content: end;\n  gap: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/SalesScoreboard/SearchForm/style.less"],"names":[],"mappings":"AAKM;EACE,4BAAA;AAJR;AAOM;EACE,qBAAA;EACA,gBAAA;AALR;AAJA;EAeI,aAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,oBAAA;EACA,SAAA;AARJ","sourcesContent":["@import '../../../styles/constants.less';\n\n.form {\n  :global {\n    .@{antd-version} {\n      &-form-item-label {\n        padding-bottom: 0 !important;\n      }\n\n      &-form-item {\n        display: inline-block;\n        margin-bottom: 0;\n      }\n    }\n  }\n\n  .buttons {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    align-items: end;\n    justify-content: end;\n    gap: 1rem;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "ZxIIjBQLh6uo7459LQSS",
	"buttons": "KK7euDkh_yDlwRDNCO4h"
};
export default ___CSS_LOADER_EXPORT___;
