import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';

export function hasPermissions(userPerms: string[], allowPerms: string[]) {
  const result = allowPerms.filter((perm) => userPerms.includes(perm));

  return Boolean(result.length);
}

export function mapToGQLPermission(perm: PERMISSION, domain: DOMAIN) {
  return domain.includes('clm')
    ? `${domain}/${perm}`
    : `gqls://${domain}/${perm}`;
}

export function getGQLPermissions(
  allow: PERMISSION | PERMISSION[],
  domain: DOMAIN | DOMAIN[]
) {
  const perms = Array.isArray(allow) ? allow : [allow];

  return perms.map((perm, i) =>
    mapToGQLPermission(perm, Array.isArray(domain) ? domain[i] : domain)
  );
}
