import { OptionModel } from '@retail/backoffice-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PeriodType } from '~/constants/PeriodType';

export function usePeriodTypeOptions() {
  const { t } = useTranslation();

  return useMemo(
    () =>
      Object.values(PeriodType).map(
        (value): OptionModel => ({
          value,
          label: t(`bo.salesScoreboard.periodType.${value}`)
        })
      ),
    [t]
  );
}
