// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".l37pgxrPohU_UDxFuNf7 {\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/SalesScoreboard/OutOfNet/styles.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF","sourcesContent":[".section {\n  text-align: center;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "l37pgxrPohU_UDxFuNf7"
};
export default ___CSS_LOADER_EXPORT___;
