import { useTranslation } from 'react-i18next';

import { OutOfNet } from './index';

interface Props {
  title: string;
}

export function OutOfNetFinancing({ title }: Props) {
  const { t } = useTranslation();

  return (
    <OutOfNet
      title={title}
      ending={t('bo.salesScoreboard.table.column.outOfNetFinancing')}
    />
  );
}
