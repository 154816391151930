import { ApolloProvider } from '@apollo/client';
import { useDevAuthToken } from '@retail/dev-auth';
import { ConfigProvider } from 'antd';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { GQL_CLIENT } from '~/apollo/gql-client';
import { ANTD_EXTERNAL_VERSION_LESS } from '~/constants/antd';
import { UserProvider } from '~/providers/UserProvider';

import { ROUTES } from './routes';

import './i18n';

export default function Root() {
  useDevAuthToken();

  return (
    <div data-qa-selector="sales-scoreboard-root">
      <ConfigProvider prefixCls={ANTD_EXTERNAL_VERSION_LESS}>
        <ApolloProvider client={GQL_CLIENT}>
          <UserProvider>
            <BrowserRouter>
              <Routes>
                {ROUTES.map(({ path, element }) => (
                  <Route key={path} path={path} element={element} />
                ))}
              </Routes>
            </BrowserRouter>
          </UserProvider>
        </ApolloProvider>
      </ConfigProvider>
    </div>
  );
}
