import { PATHS } from '@retail/backoffice-urls';
import { PathRouteProps } from 'react-router-dom';

import { SalesScoreboard } from './pages/SalesScoreboard';

export const ROUTES: PathRouteProps[] = [
  {
    path: PATHS.SALES_SCOREBOARD,
    element: <SalesScoreboard />
  }
];
