import { OptionModel } from '@retail/backoffice-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CohortType } from '~/constants/CohortType';

export function useCohortOptions() {
  const { t } = useTranslation();

  return useMemo(
    () =>
      Object.values(CohortType).map(
        (value): OptionModel => ({
          value,
          label: t(`bo.salesScoreboard.cohort.${value}`)
        })
      ),
    [t]
  );
}
