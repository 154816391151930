import { TableCell } from '@retail/backoffice-ui';
import { TableColumnType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RetailSalesScoreboardResultProjection,
  UserProjection
} from '~/apollo/gql-types';

import { OutOfNetFinancing } from '../OutOfNet/OutOfNetFinancing';
import { OutOfNetOffered } from '../OutOfNet/OutOfNetOffered';
import { OutOfNetSignings } from '../OutOfNet/OutOfNetSignings';
import { ValuePercentTableCell } from '../ValuePercentTableCell';

export function useColumns() {
  const { t } = useTranslation();

  return useMemo((): TableColumnType<RetailSalesScoreboardResultProjection>[] => {
    const config: TableColumnType<RetailSalesScoreboardResultProjection>[] = [
      {
        dataIndex: 'place',
        title: t('bo.salesScoreboard.table.column.place'),
        render: (value) => (
          <TableCell data-qa-selector="place">{value}</TableCell>
        )
      },
      {
        dataIndex: 'salesAgent',
        title: t('bo.salesScoreboard.table.column.salesAgent'),
        render: (value: UserProjection, { countryCode }) => (
          <TableCell data-qa-selector="salesAgent">
            {[value?.firstName, value?.lastName].filter(Boolean).join(' ') ||
              t('bo.salesScoreboard.table.column.salesAgent.unassigned', {
                country: t(`bo.common.country.${countryCode}`)
              })}
          </TableCell>
        )
      },
      {
        dataIndex: 'score',
        title: t('bo.salesScoreboard.table.column.score'),
        render: (value) => (
          <TableCell data-qa-selector="score">{value}</TableCell>
        )
      },
      {
        dataIndex: 'netSignings',
        title: t('bo.salesScoreboard.table.column.netSignings'),
        render: (value) => (
          <TableCell data-qa-selector="netSignings">{value}</TableCell>
        )
      },
      {
        dataIndex: 'financingSignings',
        title: (
          <OutOfNetSignings
            title={t(
              'bo.salesScoreboard.table.column.financingPercentOfNetSignings'
            )}
          />
        ),
        render: (value, { financingPercentOfNetSignings }) => (
          <ValuePercentTableCell
            value={value}
            percent={financingPercentOfNetSignings}
            data-qa-selector="financingPercentOfNetSignings"
          />
        )
      },
      {
        dataIndex: 'ppiInsuredFinancingSignings',
        title: (
          <OutOfNetFinancing
            title={t(
              'bo.salesScoreboard.table.column.ppiInsuredPercentOfNetFinancingSignings'
            )}
          />
        ),
        render: (value, { ppiInsuredPercentOfNetFinancingSignings }) => (
          <ValuePercentTableCell
            value={value}
            percent={ppiInsuredPercentOfNetFinancingSignings}
            data-qa-selector="ppiInsuredPercentOfNetFinancingSignings"
          />
        )
      },
      {
        dataIndex: 'gapInsuredFinancingSignings',
        title: (
          <OutOfNetFinancing
            title={t(
              'bo.salesScoreboard.table.column.gapInsuredPercentOfNetFinancingSignings'
            )}
          />
        ),
        render: (value, { gapInsuredPercentOfNetFinancingSignings }) => (
          <ValuePercentTableCell
            value={value}
            percent={gapInsuredPercentOfNetFinancingSignings}
            data-qa-selector="gapInsuredPercentOfNetFinancingSignings"
          />
        )
      },
      {
        dataIndex: 'premiumWarranties',
        title: (
          <OutOfNetSignings
            title={t(
              'bo.salesScoreboard.table.column.premiumWarrantiesPercentOfNetSignings'
            )}
          />
        ),
        render: (value, { premiumWarrantiesPercentOfNetSignings }) => (
          <ValuePercentTableCell
            value={value}
            percent={premiumWarrantiesPercentOfNetSignings}
            data-qa-selector="premiumWarrantiesPercentOfNetSignings"
          />
        )
      },
      {
        dataIndex: 'premium12Warranties',
        title: (
          <OutOfNetSignings
            title={t(
              'bo.salesScoreboard.table.column.premium12WarrantiesPercentOfNetSignings'
            )}
          />
        ),
        render: (value, { premium12WarrantiesPercentOfNetSignings }) => (
          <ValuePercentTableCell
            value={value}
            percent={premium12WarrantiesPercentOfNetSignings}
            data-qa-selector="premium12WarrantiesPercentOfNetSignings"
          />
        )
      },
      {
        dataIndex: 'premium24Warranties',
        title: (
          <OutOfNetSignings
            title={t(
              'bo.salesScoreboard.table.column.premium24WarrantiesPercentOfNetSignings'
            )}
          />
        ),
        render: (value, { premium24WarrantiesPercentOfNetSignings }) => (
          <ValuePercentTableCell
            value={value}
            percent={premium24WarrantiesPercentOfNetSignings}
            data-qa-selector="premium24WarrantiesPercentOfNetSignings"
          />
        )
      },
      {
        dataIndex: 'premium36Warranties',
        title: (
          <OutOfNetSignings
            title={t(
              'bo.salesScoreboard.table.column.premium36WarrantiesPercentOfNetSignings'
            )}
          />
        ),
        render: (value, { premium36WarrantiesPercentOfNetSignings }) => (
          <ValuePercentTableCell
            value={value}
            percent={premium36WarrantiesPercentOfNetSignings}
            data-qa-selector="premium36WarrantiesPercentOfNetSignings"
          />
        )
      },
      {
        dataIndex: 'secondaryWheels',
        title: (
          <OutOfNetOffered
            title={t(
              'bo.salesScoreboard.table.column.secondaryWheelsPercentOfNetSignings'
            )}
          />
        ),
        render: (value, { secondaryWheelsPercentOfNetSignings }) => (
          <ValuePercentTableCell
            value={value}
            percent={secondaryWheelsPercentOfNetSignings}
            data-qa-selector="secondaryWheelsPercentOfNetSignings"
          />
        )
      },
      {
        dataIndex: 'tradeIns',
        title: (
          <OutOfNetSignings
            title={t(
              'bo.salesScoreboard.table.column.tradeInsPercentOfNetSignings'
            )}
          />
        ),
        render: (value, { tradeInsPercentOfNetSignings }) => (
          <ValuePercentTableCell
            value={value}
            percent={tradeInsPercentOfNetSignings}
            data-qa-selector="tradeInsPercentOfNetSignings"
          />
        )
      },
      {
        dataIndex: 'cancelledSignings',
        title: t('bo.salesScoreboard.table.column.cancelledSignings'),
        render: (value) => (
          <TableCell data-qa-selector="cancelledSignings">{value}</TableCell>
        )
      }
    ];

    return config.map(
      (x): TableColumnType<RetailSalesScoreboardResultProjection> => ({
        ...x,
        align: 'center'
      })
    );
  }, [t]);
}
