import { TableCell, TableCellSpanProps } from '@retail/backoffice-ui';

interface Props extends TableCellSpanProps {
  value: number;
  percent: number;
}

export function ValuePercentTableCell({ value, percent, ...props }: Props) {
  return (
    <TableCell {...props}>
      {[value, percent].every(Number.isFinite)
        ? `${value} (${percent}%)`
        : null}
    </TableCell>
  );
}
