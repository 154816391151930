import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ServerParseError,
  createHttpLink
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { notification } from 'antd';

const { API_URL, SCHEMA_ACCESS_DOMAIN, SCHEMA_AUTH_TITLE } = process.env;

function getCookieByName(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
}

const HTTP_LINK = createHttpLink({
  uri: `/${API_URL}`
});

// NOTE Prepare common headers for all requests
const AUTH_LINK = setContext((operation, { headers }) => {
  // NOTE get the authentication access token from cookies if it exists
  const accessToken = getCookieByName('t');
  // NOTE return the headers to the context so httpLink can read them
  return {
    headers: {
      accept: 'application/json, text/plain, */*',
      'access-domain': SCHEMA_ACCESS_DOMAIN,
      'content-type': 'application/json;charset=UTF-8',
      authorization: `${SCHEMA_AUTH_TITLE} ${accessToken}`,
      ...headers
    }
  };
});

function handleErrors({
  networkError,
  graphQLErrors,
  operation,
  forward
}: ErrorResponse) {
  if ((networkError as ServerParseError)?.statusCode === 401) {
    const accessToken = getCookieByName('t');

    operation.setContext({
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return forward(operation);
  }

  if (Array.isArray(graphQLErrors)) {
    graphQLErrors.forEach(({ message, errorType }) => {
      notification.error({
        message: message || errorType || 'Oops something went wrong:['
      });
    });
  }
}

// NOTE Apollo client
export const GQL_CLIENT = new ApolloClient({
  link: ApolloLink.from([AUTH_LINK, onError(handleErrors), HTTP_LINK]),
  cache: new InMemoryCache({ typePolicies: {} }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only'
    }
  }
});
