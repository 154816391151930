import { OptionModel } from '@retail/backoffice-ui';
import { useMemo } from 'react';

import {
  RetailSalesScoreboardCountryConfigProjection,
  RetailSalesScoreboardParameterConfigProjection,
  useSalesScoreboardConfigQuery
} from '~/apollo/gql-types';

import { useColumns } from './useColumns';

interface Props {
  countryCode: string;
  countryCodeOptions: OptionModel[];
}

export function useConfiguredColumns({
  countryCode,
  countryCodeOptions
}: Props) {
  const { data, loading } = useSalesScoreboardConfigQuery({
    skip: !countryCodeOptions.length,
    fetchPolicy: 'cache-first',
    variables: {
      request: { countryCodes: countryCodeOptions.map((x) => x.value) }
    }
  });

  const columns = useColumns();

  return useMemo(() => {
    const config = data?.salesScoreboardConfig?.countriesConfig?.find(
      (x) => x.countryCode === countryCode
    );

    return {
      loading,
      columns: config
        ? columns.filter((x) => {
            const fieldConfig = config[
              x.dataIndex as keyof RetailSalesScoreboardCountryConfigProjection
            ] as RetailSalesScoreboardParameterConfigProjection;

            if (fieldConfig) {
              return fieldConfig.enabled;
            }

            return true;
          })
        : []
    };
  }, [
    columns,
    countryCode,
    data?.salesScoreboardConfig?.countriesConfig,
    loading
  ]);
}
